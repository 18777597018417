<template>
  <div>
    <div>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <b-card class="text-white-50" style="background: #4c0410">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-16 mb-0 text-white">
                    {{ agent.iam.toUpperCase() }}
                  </p>
                  <h2 class="mb-0 font-size-30 text-white">
                    {{ agent.firstname }} {{ agent.middlename }}
                    {{ agent.lastname }}
                  </h2>
                  <p class="text-truncate font-size-1 mb-0">
                    {{ agent.username }}
                    <!-- Sold {{ agent.lands_sold}} land(s) -->
                  </p>
                </div>
                <div class="text-primary" style="border: 4px solid white;">
                  <img
                    :src="profilePhoto"
                    alt="Profile Picture"
                    style="width: 80px; height: 80px; object-fit: cover"
                  />
                  <!-- <i
                    class="ri-user-2-line"
                    style="font-size: 40px; color: white"
                  ></i> -->
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->

      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title mb-4">Other Details</h4> -->
          <div class="table-responsive">
            <table class="table table-striped table-hover table-sm">
              <tr>
                <td>First Name:</td>
                <td>{{ agent.firstname }}</td>
              </tr>
              <tr>
                <td>Middlename:</td>
                <td>{{ agent.middlename }}</td>
              </tr>
              <tr>
                <td>Lastname:</td>
                <td>{{ agent.lastname }}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>
                  <a :href="'mailto:' + agent.email">{{ agent.email }}</a>
                </td>
              </tr>
              <tr>
                <td>Phone:</td>
                <td>
                  <a :href="'tel:' + agent.phone">{{ agent.phone }}</a>
                </td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>{{ agent.address }}</td>
              </tr>
              <tr>
                <td>Lands sold:</td>
                <td>{{ agent.lands_sold }}</td>
              </tr>
              <tr>
                <th colspan="2" class="bg-secondary text-white text-right pr-2">
                  Account Details
                </th>
              </tr>
              <tr>
                <td>Account Name:</td>
                <td>{{ agent.account_name }}</td>
              </tr>
              <tr>
                <td>Account Number:</td>
                <td>{{ agent.account_number }}</td>
              </tr>
              <tr>
                <td>Bank Name:</td>
                <td>{{ agent.bank_name }}</td>
              </tr>
              <tr>
                <th colspan="2" class="bg-secondary text-white text-right pr-2">
                  Account Verification
                </th>
              </tr>
              <tr>
                <td>Verified on:</td>
                <td>{{ agent.payment_date | dateFormat }}</td>
              </tr>
              <tr>
                <td>Amount:</td>
                <td>{{ agent.amount | currencyFormat }}</td>
              </tr>
              <tr>
                <td>Reference:</td>
                <td>{{ agent.payment_reference }}</td>
              </tr>
              <tr>
                <td>Mode:</td>
                <td>{{ agent.payment_mode }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    agent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    profilePhoto() {
      return this.agent.photo ?? "/img/avatar-2.43fa192e.jpg";
    },
  },
};
</script>