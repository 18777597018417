<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import ActivateAffiliates from './activate-affiliates';
import AffiliateList from './affiliates-list';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // ActivateAffiliates,
    AffiliateList
  },
  data() {
    return {
      title: "Affiliates Overview"
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-lg-12">
        <!-- <ActivateAffiliates /> -->
        <AffiliateList />
      </div>
    </div>
  </Layout>
</template>