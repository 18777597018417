<script>
import { userService } from "@/services/user.service";
import { required } from "vuelidate/lib/validators";
import AgentDetailsModal from "@/views/agent-modal";
/**
 * Transactions component
 */
export default {
  components: { AgentDetailsModal },
  data() {
    return {
      // activate affiliate
      loading: false,
      amount: "",
      selectedUser: {},
      submitted: false,
      errors: {},

      // affliliate list
      affiliates: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      fields: [
        "#",
        {
          key: "created_at",
          formatter: (value) => {
            return this.$options.filters.dateFormat(value);
          },
        },
        {
          key: "firstname",
          sortable: true,
          label: "Affiliate Name",
          formatter: (value, key, row) => {
            return row.firstname ? row.firstname + " " + row.lastname : "NA";
          },
        },
        "username",
        { key: "lands_sold", sortable: true, label: "No of Lands Sold" },
        {
          key: "referrer_username",
          label: "Referred By",
          sortable: true,
          formatter: (value) => {
            return value == this.username ? "@Me" : value;
          },
        },
        // {
        //   key: "affiliate_signup_commission_percent",
        //   sortable: true,
        //   label: "Commission %",
        //   formatter: (value, key, row) => {
        //     // no commission from indirect affiliates
        //     return row.referrer_username == this.username ? value : "NA";
        //   },
        // },
        // {
        //   key: "affiliate_signup_commission",
        //   sortable: true,
        //   label: "Commission Amount",
        //   formatter: (value, key, row) => {
        //     // no commission for indirect referrers
        //     return row.referrer_username == this.username ? value : "NA";
        //   },
        // },
        {
          key: "payment_date",
          sortable: true,
          label: "Status",
          formatter: (value) => {
            return value ? "activated" : "pending";
          },
        },
        { key: "action" },
      ],
    };
  },
  validations: {
    amount: { required, Number },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.affiliates.length;
    },
    username() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.username
        : null;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  mounted() {
    this.getReferrals();
  },
  methods: {
    async getReferrals() {
      try {
        this.isBusy = true;
        let response = await userService.getAffiliates();
        this.affiliates = response.data.data;
        this.totalRows = this.affiliates.length;
        console.log(this.affiliates);
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        alert(error);
        console.log(error);
      }
    },
    async activateUser() {
      this.$store.dispatch("notification/clear");
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (!confirm("Are you sure?\nYou cannot undo this action.")) {
        return;
      }

      try {
        this.errors = {};
        this.loading = true;
        var response = await userService.activateAffiliate(
          {
            amount: this.amount,
          },
          this.selectedUser.id
        );

        // this.$store.dispatch("notification/success", response.data.message);
        var index = this.affiliates.findIndex(
          (user) => user.id == this.selectedUser.id
        );
        this.affiliates[index].payment_date = response.data.data.payment_date;
        this.affiliates[index].amount = response.data.data.amount;
        this.affiliates[index].payment_reference =
          response.data.data.payment_reference;
        this.affiliates[index].payment_mode = response.data.data.payment_mode;
        this.loading = false;
        this.submitted = false;
        this.$refs["activate-user-modal"].hide();
      } catch (error) {
        this.loading = false;
        console.log(error);
        switch (error.response.status) {
          case 422:
            this.$store.dispatch("notification/error", "An error occurred!");
            this.errors = error.response.data.errors;
            break;
          case 404:
            this.$store.dispatch("notification/error", "Resource not found!");
            break;
          case 500 || 401 || 403:
            this.$store.dispatch(
              "notification/error",
              error.response.data.message
            );
            break;
          default:
            this.$store.dispatch(
              "notification/error",
              "Something went wrong. Please try again!"
            );
            break;
        }
      }
    },
    showActivationForm(user) {
      this.selectedUser = user;
      this.$refs["activate-user-modal"].show();
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">All Affiliates</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="affiliates"
          :fields="fields"
          responsive="sm"
          :busy="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> &nbsp; Loading...</strong>
            </div>
          </template>

          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <!-- <template #cell(affiliate_signup_commission_percent)="data">
            {{
              data.item.referrer_signup_earning
                ? data.item.referrer_signup_earning.rate + "%"
                : "NA"
            }}
          </template>

          <template #cell(affiliate_signup_commission)="data">
            {{
              data.item.referrer_signup_earning
                ? data.item.referrer_signup_earning.amount
                : 0 | currencyFormat
            }}
          </template> -->

          <template v-slot:cell(payment_date)="row">
            <div
              class="badge font-size-12"
              :class="{
                'badge-soft-success': `${row.value}` === 'activated',
                'badge-soft-warning': `${row.value}` === 'pending',
              }"
            >
              {{ row.value }}
            </div>
          </template>

          <template v-slot:cell(username)="data">
            <a
              href="javascript:void(0);"
              class="mr-3 text-success"
              v-b-tooltip.hover
              @click="
                selectedUser = data.item;
                $refs['agent-details-modal'].show();
              "
            >
              {{ data.item.username }}
            </a>
          </template>

          <template v-slot:cell(referrer_username)="data">
            <a
              href="javascript:void(0);"
              class="mr-3 text-success"
              v-b-tooltip.hover
              @click="
                selectedUser = affiliates.find((user) => user.id == data.item.referred_by);
                $refs['agent-details-modal'].show();
              "
            >
              {{ data.item.referrer_username }}
            </a>
          </template>

          <template v-slot:cell(action)="data">
            <div
              class="badge font-size-12 badge-primary"
              style="cursor: pointer"
              @click="showActivationForm(data.item)"
              v-if="data.item.payment_date == null"
            >
              Activate
            </div>
            <a
              v-else
              href="javascript:void(0);"
              class="mr-3 text-success"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Payments"
              @click="showActivationForm(data.item)"
            >
              <i class="mdi mdi-wallet font-size-18 font-weight-bold"></i>
            </a>
            <!-- <a
              href="javascript:void(0);"
              class="mr-3 text-primary btn btn-sm btn-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              activate
            </a>
            <a
              href="javascript:void(0);"
              class="text-danger"
              v-b-tooltip.hover
              title="Delete"
            >
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a> -->
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="agent-details-modal"
      size="md"
      centered
      scrollable
      title="User Info"
      title-class="font-18"
      hide-footer
    >
      <AgentDetailsModal :agent="selectedUser"/>
    </b-modal>

    <b-modal
      ref="activate-user-modal"
      size="md"
      centered
      scrollable
      :title="
        selectedUser.payment_date == null
          ? 'Activate ' + selectedUser.username
          : selectedUser.username + ' Activation Details'
      "
      title-class="font-18"
      hide-footer
    >
      <div>
        <div class="row" v-if="selectedUser.payment_date != null">
          <div class="col-sm-12 col-md-12">
            <b-card class="text-white-50" style="background: #4c0410">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-16 mb-0 text-white">
                      <i class="text-muted">Ref: </i>
                      {{ selectedUser.payment_reference }}
                    </p>
                    <h2 class="mb-0 font-size-30 text-white">
                      {{ selectedUser.amount | currencyFormat }}
                    </h2>
                    <p class="text-truncate font-size-12 mb-2">
                      {{ selectedUser.payment_date | dateFormat }} /
                      {{ selectedUser.payment_mode.toUpperCase() }}
                    </p>
                  </div>
                  <div class="text-primary">
                    <i
                      class="mdi mdi-wallet-plus"
                      style="font-size: 40px; color: white"
                    ></i>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            <b-card style="background: white" class="text-black-50">
              <!-- <div class="card-body"> -->
              <div class="media">
                <div class="media-body overflow-hidden">
                  <b-alert
                    :variant="notification.type"
                    class="mt-1"
                    v-if="notification.message"
                    show
                    dismissible
                    @dismissed="$store.dispatch('notification/clear')"
                    >{{ notification.message }}</b-alert
                  >
                  <!-- <h4 class="card-title mb-4">Add Payment</h4> -->
                  <p
                    class="font-size-14 mb-0 text-black"
                    style="margin-left: 12px"
                  >
                    Enter the amount without a comma (eg: 100000)
                  </p>
                  <div class="col-12">
                    <form @submit.prevent="activateUser">
                      <div class="form-group row mb-3">
                        <div class="col-md-12">
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter Amount Paid"
                            v-model="amount"
                            :class="{
                              'is-invalid':
                                (submitted && $v.amount.$error) ||
                                errors.amount,
                            }"
                          />
                          <div
                            v-if="
                              (submitted && $v.amount.$error) || errors.amount
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.amount"
                              >{{ errors.amount[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.amount.required"
                              >This field is required &nbsp;</span
                            >
                            <span v-if="!$v.amount.Number"
                              >This field must be a number.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class="col-md-12">
                          <button
                            class="col-md-12 btn btn-success"
                            :disabled="loading"
                          >
                            Activate Affiliate
                            <b-spinner
                              v-show="loading"
                              small
                              class="align-middle"
                            ></b-spinner>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </b-card>
          </div>
          <!-- end col -->
        </div>
      </div>
    </b-modal>
  </div>
</template>